import request from '@/utils/http'

/**
 * 插件列表
 * @param payload
 */
export function addon_list(payload) {
    return request({ url: '/plugin/Index/index', method: 'post', data: payload })
}

/**
 * 安装插件
 * @param payload
 */
export function install(payload) {
    return request({ url: '/plugin/Index/install', method: 'post', data: payload })
}

/**
 * 卸载插件
 * @param payload
 */
export function uninstall(payload) {
    return request({ url: '/plugin/Index/uninstall', method: 'post', data: payload })
}