<template>
  <div class="main-body">
    <a-tabs v-model:activeKey="activeKey" @change="changeTab">
      <a-tab-pane key="0" tab="全部"></a-tab-pane>
      <a-tab-pane key="1" tab="已安装"></a-tab-pane>
      <a-tab-pane key="2" tab="已下载"></a-tab-pane>
    </a-tabs>
    <a-table :columns="columns" :data-source="listData" :pagination="false">
      <template #bodyCell="{ column, record }">
        <template v-if="column.dataIndex === 'title'">
          <a-avatar shape="square" :src="'http://tp6.crate.com' + record.icon" class="margin-right15" />
          {{ record.title }}
        </template>
        <template v-if="column.dataIndex === 'description'">
          {{ record.description }}
        </template>
        <template v-if="column.dataIndex === 'action'">
          <a-button size="small" class="margin-right15" @click="showModalInfo(record.content)">详情</a-button>
          <template v-if="record.type != 'force'">
            <a-button v-if="record.status === 1" type="primary" danger size="small" @click="uninstall(record)">卸载</a-button>
            <a-button type="primary" size="small" @click="install(record)" v-else>安装</a-button>
          </template>
        </template>
      </template>
    </a-table>
    <a-modal v-model:visible="installAddonVisible" title="插件安装" width="600px">
      <template #footer>
        <a-button type="primary" :disabled="formState.installButtonStatus" :loading="formState.loading" @click="installHandleOk">确认安装</a-button>
      </template>

      <div>
        <p>会员基础模块</p>
        <a-divider />
        <a-table v-if="formState.need_module_data.length > 0" :columns="need_module_columns" :data-source="formState.need_module_data" :pagination="false">
          <template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex === 'action'">
              <CheckOutlined v-if="record.status" class="correct-icon" />
              <CloseOutlined v-else class="error-icon" />
            </template>
          </template>
        </a-table>

        <a-table v-if="formState.need_tables_data.length > 0" :columns="need_tables_columns" :data-source="formState.need_tables_data" :pagination="false">
          <template #bodyCell="{ column }">
            <template v-if="column.dataIndex === 'action'">
              <CheckOutlined class="correct-icon" />
            </template>
          </template>
        </a-table>

        <div style="margin-top: 20px">
          <a-form-item label="是否清除旧数据" extra="选择“是”，将删除数据库中已存在的相同数据表">
            <a-radio-group v-model:value="formState.is_clear_data">
              <a-radio value="0">否</a-radio>
              <a-radio value="1">是</a-radio>
            </a-radio-group>
          </a-form-item>
        </div>
      </div>
    </a-modal>

    <a-modal v-model:visible="addonInfoVisible" width="1000px" title="插件详情" @ok="handleOk">
      <div>
        {{ plugin_info }}
      </div>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, reactive } from "vue";
import { message, Modal } from "ant-design-vue";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons-vue";
import * as Api from "@/addons/addon/api";
const columns = [
  {
    title: "名称",
    dataIndex: "title",
    key: "title",
    width: 200,
  },
  {
    title: "介绍",
    dataIndex: "description",
    key: "description",
    ellipsis: true,
  },
  {
    title: "作者",
    dataIndex: "author",
    key: "author",
    width: 160,
  },
  {
    title: "版本",
    dataIndex: "version",
    key: "version",
    width: 100,
  },
  {
    title: "操作",
    dataIndex: "action",
    key: "action",
  },
];

const need_module_columns = [
  {
    title: "模块依赖",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "检查结果",
    dataIndex: "action",
    key: "action",
  },
];

const need_tables_columns = [
  {
    title: "数据表",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "备注",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "检查结果",
    dataIndex: "action",
    key: "action",
  },
];

export default defineComponent({
  components: {
    CheckOutlined,
    CloseOutlined,
  },
  setup() {
    // 安装插件
    const installAddonVisible = ref(false);

    const install = (info) => {
      console.log(info);
      formState.name = info.name;
      formState.need_module_data = info.need_module;
      formState.need_tables_data = info.tables;
      //是否能点击安装按钮
      formState.installButtonStatus = info.ok_install ? false : true;
      installAddonVisible.value = true;
    };

    const formState = reactive({
      loading: false,
      name: "",
      need_module_data: [],
      need_tables_data: [],
      is_clear_data: "0",
      installButtonStatus: true,
    });

    const installHandleOk = () => {
      formState.loading = true;
      Api.install({ name: formState.name })
        .then((res) => {
          formState.loading = false;
          installAddonVisible.value = false;
          message.success(res.message);
          // 重新更新列表
          onLoadData();
        })
        .catch((err) => {
          formState.loading = false;
          installAddonVisible.value = false;
          message.error(err);
        });
    };

    const key = "updatable";

    //卸载插件
    const uninstall = (info) => {
      Modal.confirm({
        title: "卸载插件?",
        content: "您确定要卸载该插件吗，卸载后相关功能将无法使用！",

        onOk() {
          Api.uninstall({ name: info.name }).then((res) => {
            console.log(res);
            message.success({
              content: "卸载成功!",
              key,
              duration: 2,
            });
          });
        },
      });
    };

    // 详情
    const addonInfoVisible = ref(false);

    const plugin_info = ref("");

    const showModalInfo = (info) => {
      plugin_info.value = info;
      addonInfoVisible.value = true;
    };

    // 关闭插件详情弹框
    const handleOk = () => {
      addonInfoVisible.value = false;
    };

    const activeKey = ref("0");

    const changeTab = (e) => {
      console.log(e);
      console.log(activeKey.value);
      onLoadData();
    };

    const listData = ref();

    // 请求列表数据
    const onLoadData = () => {
      Api.addon_list({ status: activeKey.value }).then((res) => {
        console.log(res);
        listData.value = res.data;
      });
    };

    // 初始化
    onMounted(() => {
      onLoadData();
    });

    return {
      listData,
      activeKey,
      changeTab,
      columns,
      install,
      installAddonVisible,
      installHandleOk,
      uninstall,
      addonInfoVisible,
      showModalInfo,
      plugin_info,
      handleOk,
      need_module_columns,
      need_tables_columns,
      formState,
    };
  },
});
</script>
<style scoped>
.correct-icon {
  color: #52c41a;
}

.error-icon {
  color: #ff4d4f;
}
</style>